<template>
  <div id="error">
    <MError
      :title="'500'"
      :subtitle="$t('This site is getting a tune-up')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MError from 'theme/components/molecules/m-error';
import { Logger } from '@vue-storefront/core/lib/logger';
import { metaErrorPage } from '../meta/errors';

export default defineComponent({
  name: 'Error',
  components: {
    MError
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  async asyncData ({ store, context, route }) {
    try {
      await store.dispatch('config-varus/get', { path: ['header_logo_src'] });

      Logger.log(`Calling asyncData for Error page ${new Date()}`);

      if (context) {
        context.output.cacheTags.add('error');
        if (route.params.code) {
          context.server.response?.status(route.params.code);
        }
      }

      return;
    } catch (error) {
      Logger.error('Error in asyncData:', error);
      throw error;
    }
  },
  metaInfo: metaErrorPage
});
</script>
